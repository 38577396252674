import * as _api from '../lib/api';
import * as _location from '../lib/location';
import * as _transaction from '../lib/transaction';

export default {
    methods: {
        async canSubmit() {
            if (!['delivery', 'takeaway', 'elockers'].includes(this.form.method)) {
                if ((await _location.isOpen(true)) === false) {
                    _transaction.clear();
                    location.href = '/closed';
                    return false;
                }
            }

            // check the items available
            if ((await this.checkItemsAvailability()) === false) {
                return false;
            }

            if (['delivery', 'takeaway'].includes(this.form.method)) {
                if ((await this.$refs.formdatetime.validate()) === false) {
                    return false;
                }

                if (this.$refs.formaddress && (await this.$refs.formaddress.validateAndEmit()) === false) {
                    return false;
                }
            }

            return true;
        },

        async checkItemsAvailability() {
            if (this.form.method === 'elockers') {
                return true;
            }
            const availability_response = await _api.post('items/check-availability', { items: this.transaction.lines });

            if (availability_response.result === 'success') {
                return true;
            }
            this.$refs.itemsnotavailable.open(availability_response.data.items);

            this.scrollToTop();

            return false;
        },
    },
};
